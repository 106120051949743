const proxy = require('http-proxy-middleware');

module.exports = function (app) {

  app.use(proxy('/api', {
    // target: 'http://114.115.149.95:8084',
    target: 'http://127.0.0.1:8083',
    secure: false,
    changeOrigin: true,
    pathRewrite: {
      "^/api": "/api"
    },
  }));


  app.use(proxy('/arbapi', {
    // target: 'http://114.115.149.95:8084',
    target: 'http://127.0.0.1:8084',
    secure: false,
    changeOrigin: true,
    pathRewrite: {
      "^/arbapi": "/arbapi"
    },
  }));


};
