import React from 'react';
import {
  Form, Icon, Input, Button, Checkbox, Row,
  Col, Modal, Tabs, message,
} from 'antd';
import MD5 from "react-native-md5";
import cookie from 'react-cookies'
import Tools from '@/util/tools';
import Constant from '@/constants/constant';
import './login.css';
import request from '@/util/request'

const { TabPane } = Tabs;

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      captchaimg: "",
      captchatoken: "",
      username: "",
      accountPwd: "",
      userphone: "",
      isRemember: true,
      verifyType: "1", //短信验证的类型  1、登录 2、忘记密码
      firstFocus: true,
      type1: 1,
      type2: 2,
      btnDisabled: false,
      btnTip: '获取短信验证码',
      btnId: 'api',//仲裁员和当事人身份区别
    };
    //初始化验证码
    this.refreshCaptcha();
  }

  // 在渲染前调用
  componentWillMount = () => {
    this.getCaptcha()
    const accountName = cookie.load("accountName");
    const accountPwd = cookie.load("accountPwd");
    if (!(Tools.isEmpty(accountName) || Tools.isEmpty(accountPwd))) {
      this.setState({ username: accountName, accountPwd: accountPwd });
    } else {
      this.setState({ isRemember: false });
    }
  }

  getCaptcha () {
    return request({
      url: '/arbapi/sys/auth/captcha',
      method: 'post',
      data: {}
    });
  }

  // 密码框第一次获得焦点时重置密码
  inputOnFocus = () => {
    if (this.state.firstFocus) {
      this.setState({
        accountPwd: "",
        firstFocus: false,
      });
      // 清空form表单的值
      this.props.form.setFieldsValue({
        password: ""
      })
    }
  }

  // 刷新验证码
  refreshCaptcha = () => {
    request({
      url: '/' + this.state.btnId + '/sys/auth/captcha',
      method: 'post',
      data: {}
    }).then((response) => {
      if (response.code === 200) {
        this.setState({ captchaimg: "data:image/jpeg;base64," + response.result, captchatoken: response.message });
      }
    }).catch((error) => {
      console.log(error);
    });
  }

  // 获取短信验证码
  getShortMsg = () => {
    let userphone = this.state.userphone;
    if (userphone === undefined || userphone === "") {
      Modal.error({
        title: '错误提示',
        content: "请输入正确的手机号码",
      });
    } else {
      request({
        url: '/' + this.state.btnId + '/sys/auth/getShortMsg',
        method: 'post',
        data: { userphone: userphone, verifyType: this.state.verifyType }
      }).then((response) => {
        if (response.code === 200) {
          // 设置倒计时
          let oneMinute = 60;
          // 按钮设置disable
          this.setState({ btnDisabled: true });
          let interval = setInterval(() => {
            if (oneMinute === 0) {
              clearTimeout(interval);
              this.setState({ btnDisabled: false, btnTip: "获取短信验证码" });
            } else {
              this.setState({ btnTip: (oneMinute--) + "秒" });
            }
          }, 1000);
          message.success(response.message);
        } else {
          message.error(response.message);
        }
      })
    }
  }

  // 手机号码校验
  phoneVerify = (rule, value, callback) => {
    if (typeof value === 'undefined') {
      return callback();
    }
    let phoneRegex = new RegExp('^[1][3,4,5,6,7,8,9][0-9]{9}$');
    if (phoneRegex.test(value)) {
      this.setState({ btnDisabled: false });
      return callback();
    } else {
      if (value.length > 0) {
        this.setState({ btnDisabled: true });
        return callback("手机号码格式不正确!");
      }
      return callback();
    }
  }

  // 跳转到登录页面
  goRegister = () => {
    this.props.history.push("./register");
  }

  // 跳转到重置密码页面
  forgotPwd = () => {
    window.location.href = "./resetPwd";
  }

  // 点击按钮切换身份
  changeUser = (id) => {
    this.setState({
      btnId: id
    }, () => {
      // 刷新验证码
      this.refreshCaptcha();
    })
  }



  handleChange = e => {
    this.setState({
      userphone: e.target.value
    })
  }
  // 提交登录表单
  loginFormSubmit = (type, e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      // 用户名
      let username = values.username;
      // 密码
      let password = values.password;
      // 验证码
      let captcha = values.captcha;
      // 手机号
      let userphone = values.userphone;
      // 短信验证码
      let short_msg = values.short_msg;
      if (type === 1) {
        if (username !== undefined && password !== undefined && captcha !== undefined) {
          let accountPwd = '';
          if (this.state.firstFocus) {
            accountPwd = this.state.accountPwd;
          } else {
            accountPwd = MD5.hex_md5(values.password);
          }
          request({
            url: '/' + this.state.btnId + '/sys/auth/login',
            method: 'post',
            data: {
              "captchaToken": this.state.captchatoken,
              "captcha": values.captcha,
              "accountPwd": accountPwd,
              "accountName": values.username,
              "remember": values.remember,
              "loginType": "1",
              "terminal": 'PC'
            }
          }).then((response) => {
            if (response.code === Constant.R.ok) {
              let result = response.result;
              // this.props.history.push({ pathname: '/main/modules-cms-workplace-WorkPlace/' + result.token, state: { accessToken: result.token } });
              window.location.href = result.webUrl + result.token
            } else {
              // 表单重置
              // this.props.form.resetFields();
              // 刷新验证码
              let message = response.message;
              Modal.error({
                title: message,
              });
            }
            //刷新验证码
            this.refreshCaptcha();
          }).catch((error) => {
            Modal.error({
              title: message,
            });
          });
        }
      } else if (type === 2) {
        if (userphone !== undefined && short_msg !== undefined) {
          request({
            url: '/' + this.state.btnId + '/sys/auth/login',
            method: 'post',
            data: {
              "accountName": userphone,
              "shortMsg": short_msg,
              "loginType": "2"
            }
          }).then((response) => {
            if (response.code === Constant.R.ok) {
              let result = response.result;
              window.location.href = result.webUrl + result.token
            } else {
              // 表单重置
              // this.props.form.resetFields();
              // 刷新验证码
              this.refreshCaptcha();
              let message = response.message;
              Modal.error({
                title: message,
              });
            }
          }).catch((error) => {
            Modal.error({
              title: error,
            });
          });
        }
      }
    });
  }

  render () {
    const { getFieldDecorator } = this.props.form;


    return (
      <div>

        <Row className="top-row"  >
          <Col className="t-row-left">
            <img className="brand-picture" src={require("@/static/images/brand.png")} alt="" />
            <div className="brand-title"> 互联网仲裁服务平台</div>
          </Col>
        </Row>

        <div className="tabs_switch">
          <div className="tabs-left">
            <img className="tab-img" src={require("../../static/images/sp.png")} alt="" />
          </div>
          <div className="tabs">
            <div className="tabs-header">
              <div className="tabs-title">
                欢迎登录
              </div>
              <div className="tabs-btns">
                <div className={`t-btn btn-left ${this.state.btnId === 'arbapi' ? 'active' : ''}`}
                  onClick={() => this.changeUser('arbapi')}>仲裁员</div>
                <div className={`t-btn btn-right ${this.state.btnId === 'api' ? 'active' : ''}`}
                  onClick={() => this.changeUser('api')}>当事人</div>
              </div>
            </div>

            <Tabs defaultActiveKey="1">

              <TabPane tab="密码登录" key="1"  >
                <Row gutter={[0, 24]} justify="center" align="middle">
                  <Col justify="center" align="middle">
                    <Form className="login-form">
                      <Form.Item className="form_item">
                        {getFieldDecorator('username', {
                          initialValue: this.state.username,
                          rules: [{ required: true, message: '请输入用户名!' }],
                        })(
                          <Input
                            prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                            placeholder="用户名"
                          />,
                        )}
                      </Form.Item>
                      <Form.Item className="form_item">
                        {getFieldDecorator('password', {
                          initialValue: this.state.isRemember ? '000000' : '',
                          rules: [{ required: true, message: '请输入密码!' }],
                        })(
                          <Input
                            prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                            type="password"
                            placeholder="密码"
                            onFocus={this.inputOnFocus}
                          />
                        )}
                      </Form.Item>
                      <Form.Item className="form_item">
                        <Row gutter={[0, 8]}>
                          <Col span={12}>
                            {getFieldDecorator('captcha', {
                              rules: [{ required: true, message: '请输入验证码!' }],
                            })(<Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="验证码" />)}
                          </Col>
                          <Col span={12}>
                            <img alt="点击刷新" id="captchaimg" src={this.state.captchaimg} onClick={this.refreshCaptcha} />
                          </Col>
                        </Row>
                      </Form.Item>
                      <Form.Item className="form_item jz">
               
                        {getFieldDecorator('remember', {
                          valuePropName: 'checked',
                          initialValue: true,
                        })(<Checkbox>记住账号</Checkbox>)}
                        {this.state.btnId === 'api' ? <span> <a className="forgot_pwd" onClick={this.forgotPwd} alt="">忘记密码</a> |
                          <a onClick={this.goRegister} alt="">立即注册</a></span> : ''}

                      </Form.Item>
                      <Form.Item className="form_item">
                        <Button type="primary" onClick={(e) => this.loginFormSubmit(this.state.type1, e)} className="login-form-button">
                          登录
                        </Button>
                      </Form.Item>
                    </Form>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tab="短信登录" key="2">
                <Row gutter={[0, 24]} justify="center" align="middle">
                  <Col justify="center" align="middle">
                    <Form className="login-form">
                      <Form.Item className="form_item">
                        {getFieldDecorator('userphone', {
                          rules: [{ required: true, message: '请输入手机号码!' },
                          ],
                        })(
                          <Input
                            prefix={<Icon type="phone" style={{ color: 'rgba(0,0,0,.25)' }} />}
                            placeholder="手机号码" onChange={this.handleChange.bind(this)}
                          />,
                        )}
                      </Form.Item>
                      <Form.Item className="form_item">
                        <Row gutter={8}>
                          <Col span={13}>
                            {getFieldDecorator('short_msg', {
                              rules: [{ required: true, message: '请输入验证码!' }],
                            })(<Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="验证码" />)}
                          </Col>
                          <Col span={1}>
                            <Button onClick={this.getShortMsg} disabled={this.state.btnDisabled} style={{ width: "130px" }}>{this.state.btnTip}</Button>
                          </Col>
                        </Row>
                      </Form.Item>
                      <Form.Item className="form_item">
                        <Button type="primary" className="login-form-button" onClick={(e) => this.loginFormSubmit(this.state.type2, e)}>
                          登录
                        </Button>
                      </Form.Item>
                    </Form>
                  </Col>
                </Row>
              </TabPane>
            </Tabs>
          </div>
        </div>
        <div>
                    <p style={{textAlign: 'right',position: 'relative',marginTop:'40%',marginRight:"5%"
  }}>
                      < a href="https://beian.miit.gov.cn/#/Integrated/index">闽ICP备18015942号</ a>
                    </p >
                </div>
      </div>
    );
  }
}


export default Form.create()(Login);