import React, { Fragment } from 'react';
import { Form, Icon, Input, Button, Row, Col, Modal, message } from 'antd';
import MD5 from "react-native-md5";
import { getShortMsg, resetPwd } from '@/components/api'
import './resetPwd.css';
import Tools from '@/util/tools';


class ResetPwd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      captchaimg: "",
      captchatoken: "",
      username: "",
      verifyType: "2", //短信验证的类型  1、登录 2、忘记密码 3、注册
      btnTip: "获取短信验证码",
      loading: false,
      btnDisabled: false
    };
  }

  toLogin = e => {
    window.location.href = "/login";
  }

  // 获取短信验证码
  getShortMsg = () => {
    let userphone = this.state.userphone;
    if (userphone === undefined || userphone === "") {
      Modal.error({
        title: '错误提示',
        content: "请输入正确的手机号码",
      });
    } else {
      getShortMsg(this.state.userphone, this.state.verifyType).then((response) => {
        if (response.code === 200) {
          // 设置倒计时
          let oneMinute = 60;
          // 按钮设置disable
          this.setState({ btnDisabled: true });
          let interval = setInterval(() => {
            if (oneMinute === 0) {
              clearTimeout(interval);
              this.setState({ btnDisabled: false, btnTip: "获取短信验证码" });
            } else {
              this.setState({ btnTip: (oneMinute--) + "秒" });
            }
          }, 1000);
          message.success(response.message);
        } else {
          message.error(response.message);
        }
      })
    }
  }
  handleChange = e => {
    this.setState({
      userphone: e.target.value
    })
  }


  // 重置密码
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      console.log(err)
      if (!err) {
        if (values.password.length < 6 || values.password.length > 16) {
          this.setState({ loading: false });
          message.error("请输入6-16位密码");
          return;
        }
        this.setState({ loading: true });
        resetPwd({
          accountName: values.userphone,
          shortMsg: values.short_msg,
          accountPwd: MD5.hex_md5(values.password)
        }).then((response) => {
          this.setState({ loading: false });
          if (response.code === 200) {
            message.success("密码重置成功");
            setTimeout(() => {
              this.toLogin();
            }, 1000);
          } else {
            message.error(response.message);
          }
        })
      }
    });
  };

  // 手机号码校验
  phoneVerify = (rule, value, callback) => {
    if (typeof value === 'undefined') {
      return callback();
    }
    let phoneRegex = new RegExp('^[1][3,4,5,6,7,8,9][0-9]{9}$');
    if (phoneRegex.test(value)) {
      this.setState({ btnDisabled: false });
      return callback();
    } else {
      if (value.length > 0) {
        this.setState({ btnDisabled: true });
        return callback("手机号码格式不正确!");
      }
      return callback();
    }
  }

  render () {
    const { getFieldDecorator } = this.props.form;
    return (

      <Fragment>
        <Row className="top-row"  >
          <Col span={20} className="t-row-left">
            <img className="brand-picture" src={require("@/static/images/brand.png")} alt="" />
            <div className="brand-title"> 互联网仲裁服务平台</div>
          </Col>
          <Col span={3} align="middle">
            <a onClick={this.toLogin}><p className="currAccountLogin">使用已有账号登录</p></a>
          </Col>
        </Row>

        <div className="tabs_switch">
          <div className="tabs-left">
            <img className="tab-img" src={require("../../static/images/sp.png")} alt="" />
          </div>
          <div className="tabs">

            <div className="tabs-header">
              <div className="tabs-title">
                找回密码
              </div>
            </div>
            <Col>
              <Form className="resetPwd-form" onSubmit={this.handleSubmit}>
                <Form.Item className="form_item">
                  {getFieldDecorator('userphone', {
                    rules: [{ required: true, message: '请输入手机号码!' },
                    // { pattern: new RegExp(/^[1][3,4,5,7,8,9][0-9]{9}$/, 'g'), message: '手机号码格式不正确'}
                    { validator: this.phoneVerify }
                    ],
                  })(
                    <Input
                      prefix={<Icon type="phone" style={{ color: 'rgba(0,0,0,.25)' }} />}
                      placeholder="手机号码" onChange={this.handleChange.bind(this)}
                    />,
                  )}
                </Form.Item>
                <Form.Item className="form_item" >
                  <Row gutter={8}>
                    <Col span={13}>
                      {getFieldDecorator('short_msg', {
                        rules: [{ required: true, message: '请输入验证码!' }],
                      })(<Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="验证码" />)}
                    </Col>
                    <Col span={1}>
                      <Button onClick={this.getShortMsg} disabled={this.state.btnDisabled} style={{ width: "130px" }}>{this.state.btnTip}</Button>
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item className="form_item">
                  {getFieldDecorator('password', {
                    rules: [{ required: true, message: '请输入新密码!' },
                    { validator: Tools.getPwdLvl },

                    ],
                  })(
                    <Input
                      prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                      type="password"
                      placeholder="请输入新密码"
                    />
                  )}
                </Form.Item>
                <Form.Item className="form_item">
                  <Button type="primary" className="resetPwd-form-button" htmlType="submit" loading={this.state.loading}>
                    确认修改
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </div>
        </div>
      </Fragment >

    );
  }
}


export default Form.create()(ResetPwd);