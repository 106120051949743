import { fromJS } from 'immutable';
import * as constants from './constants';

const defaultState = fromJS({
	current: 0,
	caseOnline: {
		applicantList: [],
		respondentList: [],

	},
	dataSource: [1, 2]
});

export default (state = defaultState, action) => {
	switch(action.type) {
		case constants.ADD_APPLICANT:
			const caseOnline = defaultState.get('caseOnline').toJS()
			caseOnline.applicantList.push(action.applicant)
			return state.set('caseOnline', fromJS(caseOnline))
		case constants.SET_AGENT_LIST:
			return state.set('dataSource', action.list );
		case constants.SET_CURRENT:
			return state.set('current', action.current );
		default:
			return state;
	}
}