import React from 'react';
import PrivateRouter from './components/router';
import zhCN from 'antd/es/locale/zh_CN';
import './App.less';
import './modules/t/caseEdit/style.less';
import './modules/cms/workplace/style.less';


import { ConfigProvider } from 'antd';
import { Provider } from 'react-redux';
import store from './store';


class App extends React.Component {
    render() {
        return ( <
            Provider store = { store } >
            <
            ConfigProvider locale = { zhCN } >
            <
            PrivateRouter > < /PrivateRouter> <
            /ConfigProvider> <
            /Provider>
        );
    }
}

export default App;