import React from 'react'
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";
import Register from '@/modules/sys/register';
import Login from '@/modules/sys/login'
import ResetPwd from '@/modules/sys/resetPwd';
export const asyncComponent = loadComponent => (

    class AsyncComponent extends React.Component {
        constructor(...args) {
            super(...args);

            this.state = {
                Component: null,
            };

            this.hasLoadedComponent = this.hasLoadedComponent.bind(this);
        }
        componentWillMount() {
            if (this.hasLoadedComponent()) {
                return;
            }

            loadComponent()
                .then(module => module.default ? module.default : module)
                .then(Component => {
                    this.setState({
                        Component
                    });
                })
                .catch(error => {
                    /*eslint-disable*/
                    console.error('cannot load Component in <AsyncComponent>');
                    /*eslint-enable*/
                    throw error;
                })
        }
        hasLoadedComponent() {
            return this.state.Component !== null;
        }
        render() {
            const {
                Component
            } = this.state;

            return (Component) ? <Component {...this.props} /> : null;
        }
    }
);

class PrivateRouter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: false,
            perms: []
        };
    }

    render() {
        return (
            <Router>
                <Switch>
                    <Route
                        exact
                        path= "/"
                        render={() => <Redirect to={ '/login'} />}
                    />
                    <Route path={'/login'} component={Login} />
                    <Route path={'/register'} component={Register} />
                    <Route path={'/resetPwd'} component={ResetPwd} />
                    <Route path={'/main/:path/:token'} component={asyncComponent(() => import('./adminlayout'))} />
                </Switch>
            </Router>
        )
    }

}

export default PrivateRouter;