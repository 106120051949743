import * as constants from './constants';

export const setAgentList = (list) => ({
    type: constants.SET_AGENT_LIST,
    list
})

export const setCurrent = (current) => ({
    type: constants.SET_CURRENT,
    current
})


export const addApplicant = (applicant) => ({
    type: constants.ADD_APPLICANT,
    applicant
})