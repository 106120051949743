import React from 'react';
import { message, Modal } from 'antd';
import moment from 'moment'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { removeAttach } from '../components/api'
import request from '@/util/request';
const Tools = {
  redirectTo: (mainObject, path) => {
    mainObject.props.history.push({ pathname: path });
  },
  getBase64: (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  },
  handleImgBeforeUpload: (file) => {
     return new Promise((resolve, reject) => {
         //限制图片 格式、size
         const imgTypeList = ['image/jpeg', 'image/gif', 'image/png', 'image/bmp','application/pdf'];
         let isImg = false;
         imgTypeList.forEach((item) => {
             if (file.type === item) {
                 isImg = true;
                 return;
             }
         });
         if (!isImg) {
             Modal.error({
                 title: '只能上传JPG、JPEG、GIF、PNG、BMP格式的图片或pdf文件'
             })
         }
         const isLt2M = file.size / 1024 / 1024 <= 5;
         if (!isLt2M && isImg ) {
             Modal.error({
                 title: '文件大小超过5M限制 不允许上传'
             })
         }
         const ret = isImg && isLt2M;
         return ret ? resolve(true) : reject(false);
     });
  },
  
  headPortraitUploads: (file) => {
    return new Promise((resolve, reject) => {
        //限制图片 格式、size
        const imgTypeList = ['image/jpeg', 'image/gif', 'image/png', 'image/bmp'];
        let isImg = false;
        imgTypeList.forEach((item) => {
            if (file.type === item) {
                isImg = true;
                return;
            }
        });
        if (!isImg) {
            Modal.error({
                title: '只能上传JPG、JPEG、GIF、PNG、BMP格式的图片'
            })
        }
        const isLt2M = file.size / 1024 / 1024 <= 5;
        if (!isLt2M && isImg ) {
            Modal.error({
                title: '文件大小超过5M限制 不允许上传'
            })
        }
        const ret = isImg && isLt2M;
        return ret ? resolve(true) : reject(false);
    });
 },
  fileRemove: (_this, file, stateObject, stateObjectName) => {
    const token = _this.state.token;
    const fileList = stateObject.fileList;
    const index = fileList.indexOf(file);
    Modal.confirm({
      title: '您确认移除该文件吗?',
      icon: <ExclamationCircleOutlined />,
      content: '',
      onOk() {
        let data = { ids: [file.uid] };
        removeAttach(token, data).then((response) => {
          if (response.code === 200) {
            fileList.splice(index, 1);
            _this.setState({
              [stateObjectName]: {
                fileList: fileList
              }
            });
          }
        }).catch((error) => message.error("删除失败"));
      },
      onCancel() { },
    });
  },
  handleFormValues: (values) => {
    Object.keys(values).forEach((item, i) => {
      let obj = values[item];
      if (obj !== null && obj !== undefined) {
        if (typeof obj === 'boolean') {
          values[item] = obj ? 1 : 0;
        } else {
          if (Array.isArray(obj)) {
            values[item] = obj.toString();
          } else if (obj._isAMomentObject) {
            values[item] = moment(obj).format('YYYY-MM-DD HH:mm:ss');
          }
        }
      }
    });
  },
  attachBatchIdChange: (_this, info) => {
    let fileList = [...info.fileList];
    // Only to show two recent uploaded files, and old ones will be replaced by the new
    // fileList = fileList.slice(-2);
    fileList = fileList.map(file => {
      if (file.response) {
        file.uid = file.response.result[0].attachId;
        file.url =  '/api/t/tuserattachment/file?attachId=' + file.response.result[0].attachId + "&access_token=" + _this.state.token;
      }
      return file;
    });
    console.log(fileList);
  },
  attachImgChange: (_this, { fileList }) => {
    fileList = fileList.map(file => {
      if (file.response) {
        file.uid = file.response.result[0].attachId;
        file.url =  '/api/t/tuserattachment/file?attachId=' + file.response.result[0].attachId + "&access_token=" + _this.state.token;
      }
      return file;
    });
  },
  attachList2FileList: (_this, attachList) => {
    const fileList = [];
    attachList.forEach((item) => {
      fileList.push({
        uid: item.attachId,
        name: item.attachOriName,
        url:  '/api/t/tuserattachment/file?attachId=' + item.attachId + "&access_token=" + _this.state.token
      });
    });
    return fileList;
  },
  translateDictVal: (val, dictValues) => {
    let ret = '';
    dictValues.forEach((item) => {
      if (item.value === val + '') {
        ret = item.label;
        return;
      }
    });
    return ret;
  },
  updateSelectedTags: (_this, arrayPropName, selectionType, idPropName, textPropName, selectedRows, row, selected) => {
    if (selectionType === "radio") {
      const tags = _this.state[arrayPropName];
      tags.forEach((item, i) => {
        tags.splice(i, 1);
      })
      const selectedRow = selectedRows[0];
      const tag = { id: selectedRow[idPropName], text: selectedRow[textPropName] };
      tags.push(tag);
    } else {
      const tags = _this.state[arrayPropName];
      if (row !== undefined && selected !== undefined) {
        if (!selected) {
          tags.forEach((item, i) => {
            if (item.id === row[idPropName]) {
              tags.splice(i, 1);
            }
          });
        }
      }
      selectedRows.forEach((item) => {
        const tag = { id: item[idPropName], text: item[textPropName] };
        if (!Tools.existsTag(tags, tag)) {
          tags.push(tag);
        }
      });
    }
  },
  existsTag: (tags, tag) => {
    let ret = false;
    tags.forEach(item => {
      if (item.id === tag.id) {
        ret = true;
        return;
      }
    });
    return ret;
  },
  removeTag: (_this, keyVal, arrayPropName) => {
    const tags = _this.state[arrayPropName];
    tags.forEach((item, i) => {
      if (item.id === keyVal) {
        tags.splice(i, 1);
        return;
      }
    });
  },
  initTableSelected: (_this, arrayPropName, record, idPropName) => {
    let ret = false;
    _this.state[arrayPropName].forEach((item) => {
      if (record[idPropName] === item.id) {
        ret = true;
        return;
      }
    });
    return ret;
  },

  // 非空判断
  isEmpty: (obj) => {
    if (typeof (obj) === 'undefined' || obj === null || obj === '' || obj === "") {
      return true;
    }
    return false;
  },


  // 密码校验
  getPwdLvl: (rule, pwd, callback) => {
    if (typeof pwd === 'undefined') {
      return callback("");
    }
    var lvl = 0;//默认是0级
    //密码中是否有数字,或者是字母,或者是特殊符号
    if (/[0-9]/.test(pwd)) {
      lvl++;
    }
    //判断密码中有没有字母
    if (/[a-zA-Z]/.test(pwd)) {
      lvl++;
    }
    //判断密码中有没有特殊符号
    if (/[^0-9a-zA-Z_]/.test(pwd)) {
      lvl++;
    }
    // 判断密码长度
    if (pwd.length >= 6 && pwd.length <= 16) {
      lvl++;
    }
    // 判断是否含有空格
    if (/\s/.test(pwd) || /^[\u4e00-\u9fa5]/.test(pwd)) {
      return callback("密码中不能包含中文或空格");
    }
    if (lvl === 4) {
      return callback();
    }
    return callback("密码长度为6至16位,包含数字+字母+特殊字符");//最小的值是1,最大值是4
  },
  // 手机号码校验
  phoneVerify: (rule, value, callback) => {
    if (typeof value === 'undefined') {
      return callback();
    }
    let phoneRegex = new RegExp('^[1][3,4,5,6,7,8,9][0-9]{9}$');
    if (phoneRegex.test(value)) {
      this.setState({ btnDisabled: false });
      return callback();
    } else {
      if (value.length > 0) {
        this.setState({ btnDisabled: true });
        return callback("手机号码格式不正确!");
      }
    }
    return callback();
  },
}




export default Tools

