import request from '../util/request'


export function doPost(accessToken, url, data) {
  return request({
    accessToken: accessToken,
    url: url,
    method: 'post',
    data: data
  });
}


export function getCaptcha() {
  return request({
    url: '/api/sys/auth/captcha',
    method: 'post',
    data: {}
  });
}
//登录认证
export function login(data) {
  return request({
    url: '/api/sys/auth/login',
    method: 'post',
    data: data
  });
}
//登出
export function logout(accessToken) {
  return request({
    accessToken: accessToken,
    url: '/api/sys/auth/logout',
    method: 'post',
    data: {}
  });
}
//取得当前账号名
export function getCurrentUser(accessToken) {
  return request({
    accessToken: accessToken,
    url: '/api/sys/auth/currentuser',
    method: 'post',
    data: {}
  });
}
//根据type名称获取字典项列表
export function getDictValues(accessToken, type) {
  return request({
    accessToken: accessToken,
    url: '/api/sys/sysdictvalue/selectSysDictValueList',
    method: 'post',
    data: { dictTypeIdType: type }
  });
}
//获取文件批次ID
export function getAttachBatchId(accessToken) {
  return request({
    accessToken: accessToken,
    url: '/api/t/tuserattachment/attachBatchId',
    method: 'post',
  });
}

//删除文件记录
export function removeAttach(accessToken, data) {
  return request({
    accessToken: accessToken,
    url: '/api/t/tuserattachment/deleteTUserAttachment',
    method: 'post',
    data: data
  });
}
//取附件列表
export function getAttachList(accessToken, attachBatchId,attachFileType,attachId) {
  if(attachFileType==null){
      attachFileType='';
  }
  if(attachId==null){
    attachId='';
}
  return request({
    accessToken: accessToken,
    url: '/api/t/tuserattachment/selectTUserAttachmentList',
    method: 'post',
    data: { attachBatchId: attachBatchId,attachFileType:attachFileType,attachId:attachId }
  });
}

//获取手机验证码
export function getShortMsg(userphone, verifyType) {
  return request({
    url: '/api/sys/auth/getShortMsg',
    method: 'post',
    data: { userphone: userphone, verifyType: verifyType }
  })
}

// 注册
export function register(data) {
  return request({
    url: '/api/sys/auth/register',
    method: 'post',
    headers: {
      'Content-Type':"multipart/form-data",
    },
    data: data
  })
}

// 重置密码
export function resetPwd(data) {
  return request({
    url: '/api/sys/auth/resetPwd',
    method: 'post',
    data: data
  })
}
export function getMyCaseList(accessToken, data) {
  return request({
    accessToken: accessToken,
    url: '/api/t/tcaseonline/selectMyCaseOnline',
    method: 'post',
    data: data
  });
}

// 返回登录界面
export function toLogin() {
  window.location.href = "./login";
}

// 获取案由列表
export function getReasonList(accessToken) {
  return request({
    accessToken: accessToken,
    url: '/api/t/caseReason/getReasonTree',
    method: 'post',
    data: {}
  })
}

// 获取当前用户待提交的案件
export function getToSubmitCase(accessToken) {
  return request({
    accessToken: accessToken,
    url: '/api/t/tcaseonline/getToSubmitCase',
    method: 'post',
    data: {}
  })
}

// 更新当事人材料列表
export function initPartiesMaterialList(accessToken, caseDocList) {
  return request({
    accessToken: accessToken,
    url: '/api/t/tcasedoconline/initPartiesMaterialList',
    method: 'post',
    data: { caseDocList: caseDocList },
  })
}

// 更新待提交案件所处步骤
export function updateCurrentStep(accessToken, currentStep, caseId) {
  return request({
    accessToken: accessToken,
    url: "/api/t/tcaseonline/updateCurrentStep",
    method: 'post',
    data: { currentStep: currentStep, id: caseId }
  })
}

// 根据ID获取案件信息
export function selectTCaseOnlineById(accessToken, caseId) {
  return request({
    accessToken: accessToken,
    url: "/api/t/tcaseonline/selectTCaseOnlineById",
    method: 'post',
    data: { id: caseId }
  })
}

// 根据标的额查询手续费
export function costCaculator(accessToken, amountMoney) {
  return request({
    accessToken: accessToken,
    url: "/api/t/tcasepayonline/selectServiceCharge",
    method: 'post',
    data: { amountMoney: amountMoney }
  })
}

// 获取通知消息
export function selectTCaseNoticePage(accessToken, data) {
  return request({
    accessToken: accessToken,
    url: "/api/t/tcasenotice/selectTCaseNoticePage",
    method: 'post',
    data: data
  })
}

// 根据id获取通知详情
export function selectCaseNoticeById(accessToken, data) {
  return request({
    accessToken: accessToken,
    url: "/api/t/tcasenotice/selectCaseNoticeById",
    method: 'post',
    data: data
  })
}

// 根据登录用户查询未阅读消息数
export function countUnreadCaseNotice(accessToken) {
  return request({
    accessToken: accessToken,
    url: "/api/t/tcasenotice/countUnreadCaseNotice",
    method: "post"
  })
}

// 下载附件
export function downloadNoticeFile(accessToken, data) {
  return request({
    accessToken: accessToken,
    url: "/api/t/tcasenotice/downloadNoticeFile",
    method: 'post',
    data: data,
  })
}

// 修改通知状态
export function updateCaseNotice(accessToken, data) {
  return request({
    accessToken: accessToken,
    url: "/api/t/tcasenotice/updateCaseNotice",
    method: 'post',
    data: data,
  })
}

// 一键已读
export function readAllNotice(accessToken) {
  return request({
    accessToken: accessToken,
    url: "/api/t/tcasenotice/readAllNotice",
    method: 'post',
    data: {}
  })
}


// 验证是否存在可下载文件，如果存在则批量下载至临时文件内
export function docTreeBatchDownload(accessToken, data) {
  return request({
    accessToken: accessToken,
    url: '/api/t/tcasedoconline/docTreeBatchDownload',
    method: 'post',
    data: data
  })
}

// 查询国家/地区列表
export function selectLocationList(accessToken, data) {
  return request({
    accessToken: accessToken,
    url: '/api/t/tlocation/selectAllLocationList',
    method: 'post',
    data: data
  })
}


// 保存当事人信息