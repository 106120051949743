import React from 'react';
import { Form, Icon, Input, Button, Row, Col, message } from 'antd';
import MD5 from "react-native-md5";
import { register, getShortMsg, toLogin} from '@/components/api'
import './register.css';
import Tools from '@/util/tools';
import CryptoJS from 'crypto-js';
import axios from 'axios'
import { file } from '@babel/types';

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      verifyType: "3", //短信验证的类型  1、登录 2、忘记密码 3、注册
      btnTip: "获取短信验证码",
      loading: false,
      btnDisabled: false
    };
  }

  // 获取短信验证码
  getShortMsg = () => {
    const { verifyType } = this.state;
    const userphone = this.props.form.getFieldValue("userphone");
    getShortMsg(this.getAesString(JSON.stringify(userphone)), verifyType).then((response) => {
      if (response.code === 200) {
        // 设置倒计时
        let oneMinute = 60;
        // 按钮设置disable
        this.setState({ btnDisabled: true });
        let interval = setInterval(() => {
          if (oneMinute === 0) {
            clearTimeout(interval);
            this.setState({ btnDisabled: false, btnTip: "获取短信验证码" });
          } else {
            this.setState({ btnTip: (oneMinute--) + "秒" });
          }
        }, 1000);
        message.success(response.message);
      } else {
        message.error(response.message);
      }
    })
  }


  // base64转file
  dataURLtoFile(dataurl, filename) {
      let arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);
      while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
  }
  // url转base64
  getImgToBase64(url,callback){
    var canvas = document.createElement('canvas'),
      ctx = canvas.getContext('2d'),
      img = new Image;
    img.crossOrigin = 'Anonymous';
    img.onload = function(){
      canvas.height = img.height;
      canvas.width = img.width;
      ctx.drawImage(img,0,0);
      var dataURL = canvas.toDataURL('image/png');
      callback(dataURL);
      canvas = null;
    };
    img.src = url;
  }
  // 注册
  handleSubmit = e => {
    e.preventDefault();
    const _this = this;
    this.props.form.validateFields((err, values) => {
       if (!err) {
        var file = '';
        // 获取默认头像的base64编码
        _this.getImgToBase64(require('@/modules/cms/workplace/img/mrtx.png'),function(data){
              // base64转成file
          　  file = _this.dataURLtoFile(data,'mrtx.png')
              let formData = new FormData();
              formData.append("accountName", values.userphone);
              formData.append("shortMsg",  values.short_msg);
              formData.append("file", file);
              formData.append("accountPwd", MD5.hex_md5(values.password));
              axios.post( '/api/sys/auth/register', formData, {
                  "Content-Type": "multipart/form-data",
                }).then((response) => {
                  if (response.data.code === 200) {
                    message.success("注册成功");
                    setTimeout(() => {
                      _this.props.history.push("./login");
                    }, 1000);
                  } else {
                    message.error("注册失败：" + response.data.message);
                  }
                })
        })

       }

    })

  };

  // 手机号码校验
  phoneVerify = (rule, value, callback) => {
    if (typeof value === 'undefined') {
      return callback("")
    }
    let phoneRegex = new RegExp('^[1][3,4,5,6,7,8,9][0-9]{9}$');
    if (phoneRegex.test(value)) {
      this.setState({ btnDisabled: false });
      return callback();
    } else {
      if (value.length > 0) {
        this.setState({ btnDisabled: true });
        return callback("手机号码格式不正确!");
      }
    }
  }

  //加密
  encrypt(word) {
    var keyStr = 'abcdsxyzhkj12345'; 
    var key = CryptoJS.enc.Utf8.parse(keyStr);
    var srcs = CryptoJS.enc.Utf8.parse(word);
    var encrypted = CryptoJS.AES.encrypt(srcs, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
    return encrypted.toString();
}
//解密
decrypt(word) {
    var keyStr = 'abcdsxyzhkj12345'; 
    var key = CryptoJS.enc.Utf8.parse(keyStr);
    var decrypt = CryptoJS.AES.decrypt(word, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
    return CryptoJS.enc.Utf8.stringify(decrypt).toString();
}

// SHA512加密
SHA512(str) {
    // var fs = require("fs");
    var SHA512 = require("crypto-js/sha512");
    //进行加密
    var hashed = SHA512(str).toString();
    //输出加密后的哈希sha512
    return hashed;
}

getAesString(data){
var key  = CryptoJS.enc.Utf8.parse("1234567890adbcde"); 
var iv   = CryptoJS.enc.Utf8.parse("1234567890hjlkew"); 
var encrypted =CryptoJS.AES.encrypt(data,key,{
  iv:iv, 
  mode:CryptoJS.mode.CBC,
  padding:CryptoJS.pad.Pkcs7 
});
return encrypted.toString();  //返回的是base64格式的密文
}

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <Row className="top-row"  >
          <Col span={20} className="t-row-left">
            <img className="brand-picture" src={require("@/static/images/brand.png")} alt="" />
            <div className="brand-title"> 互联网仲裁服务平台</div>
          </Col>
          <Col span={3} align="middle">
          <a onClick={toLogin} className="currAccountLogin">使用已有账号登录</a>
          </Col>
        </Row>

        <div className="tabs_switch">
          <div className="tabs-left">
            <img className="tab-img" src={require("../../static/images/sp.png")} alt="" />
          </div>
          <div className="tabs">

            <div className="tabs-header">
              <div className="tabs-title">
              账 号 注 册
              </div>
            </div>
            <Col >
            <Form className="register-form" >
              <Form.Item className="form_item">
                {getFieldDecorator('userphone', {
                  rules: [{ required: true, message: '请输入手机号码!' },
                  { validator: this.phoneVerify }
                  ],
                })(
                  <Input
                    prefix={<Icon type="phone"
                      style={{ color: 'rgba(0,0,0,.25)' }} />}
                    placeholder="手机号码"
                  />,
                )}
              </Form.Item>

              <Form.Item className="form_item">
                <Row gutter={8}>
                  <Col span={13}>
                    {getFieldDecorator('short_msg', {
                      rules: [{ required: true, message: '请输入验证码!' }],
                    })(<Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="验证码" />)}
                  </Col>
                  <Col span={1}>
                    <Button onClick={this.getShortMsg} loading={this.state.loading} disabled={this.state.btnDisabled} style={{ width: "130px" }}>{this.state.btnTip}</Button>
                  </Col>
                </Row>
              </Form.Item>


              <Form.Item className="form_item">
                {getFieldDecorator('password', {
                  rules: [{ required: true, message: '请输入新密码!' },
                  { validator: Tools.getPwdLvl }
                  ],
                })(
                  <Input
                    prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    type="password"
                    placeholder="密码"
                  />
                )}
              </Form.Item>
              <Form.Item className="form_item">
                <Button type="primary" className="register-form-button" onClick={this.handleSubmit}>
                  立即注册
                        </Button>
              </Form.Item>
            </Form>
          </Col>
          </div>
        </div>
      </div>
    );
  }
}


export default Form.create()(Register);
