const Constant = {
    R: {
        ok: 200,
        error: 500
    },
    menus: [{
        id: "1",
        name: "首页",
        url: "",
        subs: [{
                id: "3.1",
                icon: "appstore",
                name: "工作台",
                url: "",
                subs: [{
                    id: "3.1.1",
                    name: "工作台",
                    url: "modules-cms-workplace-WorkPlace",
                }]
            },
            {
                id: "3.2",
                name: "在线立案",
                icon: "audit",
                url: "",
                subs: [{
                        id: "3.2.1",
                        name: "在线立案",
                        url: "modules-cms-workplace-components-CaseAdd",
                    },
                    
                    {
                        id: "3.2.2",
                        name: "我的立案",
                        url: "modules-t-mineCase-MineCase",
                    }
                ]
            },
            {
                id: "3.3",
                name: "我的案件",
                icon: "audit",
                url: "",
                subs: [{
                        id: "3.3.1",
                        name: "我是申请人",
                        url: "modules-t-caseEdit-ApplyPerson",
                    },
                    {
                        id: "3.3.2",
                        name: "我是被申请人",
                        url: "modules-t-caseEdit-ToApply",
                    },
                    {
                        id: "3.3.3",
                        name: "我是代理人",
                        url: "modules-t-caseEdit-AgencyPerson",
                    }
                ]
            },
        
            {
                id: "3.9",
                name: "互联网庭审",
                icon: "message",
                url: "",
                subs: [{
                    id: "3.9.1",
                    name: "庭审列表",
                    url: "modules-t-holdCourt-List",
                }]
            },
            {
                id: "3.10",
                name: "个人中心",
                icon: "message",
                url: "",
                subs: [{
                    id: "3.10.1",
                    name: "个人中心",
                    url: "modules-t-personalCenter-PersonalInfo",
                },
                {
                    id: "3.7.1",
                    name: "代理人管理",
                    url: "modules-t-caseEdit-DeputyAdmin",
                },
                {
                    id: "3.8.1",
                    name: "通知信息列表",
                    // url: "modules-t-caseEdit-InformAdmin"
                    url: "modules-t-messageNotice-CaseNoticeList"
                },
                
            ]
            }
        ]
    }],
}
export default Constant